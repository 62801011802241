// DateBox.tsx
import { Box, Flex, Text } from "@mantine/core";
import dayjs from "dayjs";

const DateBox = (props: {
  date: any;
  index: number;
  dateSelect: any;
  handleDateSelected(key: number, date: any, price: string): any;
  price: string;
}) => {
  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing.md,
        borderRadius: theme.radius.md,
        cursor: "pointer",
        backgroundColor:
          props.dateSelect.id === props.index && props.dateSelect.isSelect
            ? theme.colorScheme === "dark"
              ? theme.colors.dark[5]
              : theme.colors.gray[1]
            : theme.colors.white,
      })}
      onClick={props.price !== 'Non disponible' ? () => props.handleDateSelected(props.index, props.date, props.price) : undefined}
    >
      <Flex
        direction={{ base: "column", sm: "row" }}
        gap={{ base: "sm", sm: "lg" }}
        justify={{ base: "space-between", sm: "space-between" }}

        align="center"
      >
        <Text>
          <strong>{dayjs(props.date).format("dddd DD")}</strong>
        </Text>
        <Flex align={{ base: "center", sm: "flex-end" }} direction="column">
          {props.price !== "Non disponible" && (props.price as any) !== "0" ? (
            <>
              <Text className={"from-price"} sx={{ whiteSpace: "pre-line" }}>
                A partir de{"\n"}
              </Text>
              <Flex align="center" direction="row" gap="xs">
                <Text className={"price"}>{props.price}€</Text>
                <Text className={"from-price"}>/pers</Text>
              </Flex>
            </>
          ) : (props.price as any) === "0" ? (

            <Text className={"from-price"}>Non Disponible</Text>

          ) : (

            <Text className={"from-price"}>{props.price}</Text>

          )}

        </Flex>


      </Flex>
    </Box>

  );
};

export default DateBox;
