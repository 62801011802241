/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import {
  Container,
  Button,
  Flex,
  Grid,
  Image,
  Title,
  Text,
  Select,
  Space,
  Loader
} from "@mantine/core";
import { useEffect, useState } from "react";
import CureSection from "./components/cureSection/CureSection";
import banniere from "./images/plage_bab.jpg";
import cureDataProvider from "./DataProvider/cureDataProvider";
import datesPriceDataProvider from "./DataProvider/datesPriceDataProvider";
import categoryCureDataProvider from "./DataProvider/categoryCureDataProvider";
import useDocumentTitle from "./useDocumentTitle";
import roomCategoryDataProvider from "./DataProvider/roomCategoryDataProvider";
import dayjs from "dayjs";
import productDataProvider from "./DataProvider/productDataProvider";
import { getConstants } from "./constants";
import { useLocation } from 'react-router-dom';
import hotelDataProvider from "./DataProvider/hotelDataProvider";

const Home = () => {



  const [renduState, setRenduState] = useState<JSX.Element>();
  const [isLoading, setIsLoading] = useState(true);
  const [allhotels, setAllHotels] = useState<any[]>([]);
  const [olddestination, setmyolddestination] = useState<number>(999999);
  const [alldestination, setAlldestination] = useState<{ value: string; label: string }[]>([]);
  const [alltypes, setAlltypes] = useState<{ value: string; label: string }[]>([]);
  const [selectedDestination, setSelectedDestination] = useState("0");
  const [selectedType, setSelectedType] = useState("0");
  const [selectedCategory, setSelectedCategory] = useState<any>("");
  //  const [AllPrice, setAllPrice] = useState<any[]>([]);

  let buttonStates = ({
    minnight: 0,
    maxnight: 0,
    isActive: false,
  });

  const [buttonStates2, setButtonStates2] = useState<any>({
    minnight: 0,
    maxnight: 0,
    isActive: false,
  });

  let constants: any;
  let allmyhotel: any;
  let AllPrices: any;
  const end = dayjs().add(1, "year").format("YYYY-MM-DD");
  const start = dayjs().add(7, "day").format("YYYY-MM-DD");


  const location = useLocation();

  useEffect(() => {

    const params = new URLSearchParams(location.search);
    const day = params.get('day');
    const Numberday = Number(day);

    const dest = params.get('dest');
    let Numberdest = Number(dest);
    setSelectedDestination(Numberdest.toString())

    const type = params.get('type');
    let Numbertype = Number(type);
    setSelectedType(Numbertype.toString())

    // Filtre pour le nombre de jours
    if (Numberday) {
      if (Numberday === 1 || Numberday === 2) {
        handleNbNightClick(1, 2, Numberdest, true, Numbertype);
      } else if (Numberday > 2 && Numberday < 6) {
        handleNbNightClick(3, 5, Numberdest, true, Numbertype);
      } else if (Numberday > 5) {
        handleNbNightClick(6, 999, Numberdest, true, Numbertype);
      }
    } else {
      processData(true, Numberdest, Numbertype);
    }

    processDestination();
    processType();

  }, []);

  const fetchAllHotels = async () => {
    const hotels = await hotelDataProvider.getAllHot()
    return hotels.resultat
  }

  const fetchDestinations = async () => {
    const destina = await hotelDataProvider.getAllHot()
    return destina.resultat || []
  }

  const fetchTypes = async () => {
    const types = await hotelDataProvider.getAllTyp()
    return types.resultat
  }



  const fetchData = async (hotel: any) => {
    try {
      const [
        curesResponse,
        categoryResponse,
        cureOptionResponse,
        productListResponse
      ] = await Promise.all([
        cureDataProvider.getCureListHotel("cures", hotel.id),
        categoryCureDataProvider.getCategoryNight("nights"),
        cureDataProvider.getCureOption("option"),
        productDataProvider.getProduct("product", constants.Service_Id, constants)
      ]);
      hotel.Data = curesResponse;
      hotel.categoryNight = categoryResponse.resultat;
      hotel.cureOption = cureOptionResponse.resultat;
      hotel.Products = productListResponse.Products;
      try {
        hotel.Petitdej = (productListResponse.Products.find((item: { Id: string; }) => item.Id === constants.petit_dejeuner_id).Price.GrossValue);

      } catch {
        hotel.Petitdej = 0
      }

    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const fetchAvaibility = async (hotel: any) => {
    const end = dayjs().add(1, "year").format("YYYY-MM-DD");
    const start = dayjs().add(7, "day");

    const ServiceId = constants.Service_Id;
    const response = await roomCategoryDataProvider.getAllAvailability("resources", ServiceId, start.toString(), end.toString(), constants);

    const rooms = await datesPriceDataProvider.getAllRoomCategory("allRoom");

    const filtered = response.filter((response: { CategoryId: any; }) =>
      rooms.resultat.find((room: { mews_id: any; }) => room.mews_id === response.CategoryId)
    );

    hotel.Avaibility = filtered;
  };

  async function fetchPriceAllBedroom(hotel: any, stay: any) {
    const end = dayjs().add(1, "year").format("YYYY-MM-DD");
    const start = dayjs().add(7, "day").format("YYYY-MM-DD");
    const roomsprices = await datesPriceDataProvider.getAllPriceByDate("datePrice", start.toString(), end.toString(), stay.id);

    let MinPriceDate = [];

    for (const room of roomsprices) {
      const minPrices = room.prices_single.map((price_single: number, index: string | number) =>
        Math.min(price_single, room.price_two_curists[index], room.price_curist_companion[index])
      );

      for (let i = 0; i < minPrices.length; i++) {
        if (MinPriceDate[i] === undefined || MinPriceDate[i] > minPrices[i]) {
          MinPriceDate[i] = minPrices[i];
        }
      }
    }

    stay.MinPriceBedroom = MinPriceDate;
  }

  async function processDestination() {
    let alldest = await fetchDestinations();
    // alldest = alldest.filter((hotel: { id: number; }) => hotel.id === 2 || hotel.id === 3);

    const mesdest = alldest
      .sort((a: { id: number; }, b: { id: number; }) => b.id - a.id) // Tri par ID décroissant
      .map((destination: { id: number; name: any; }) => ({
        value: destination.id.toString(),
        label: destination.name,
      }));

    mesdest.unshift({
      value: "0",
      label: "Toutes",
    })

    setAlldestination(mesdest)
  }

  async function processType() {
    const alltype = await fetchTypes();
    const mestypes = alltype.map((type: { id: { toString: () => any; }; title: any; }) => ({
      value: type.id.toString(),
      label: type.title,
    }));

    mestypes.unshift({
      value: "0",
      label: "Toutes",
    })

    setAlltypes(mestypes)
  }

  async function processData(first: boolean, destination: number, type: number) {
    let hotels: any;

    // Fonction récursive pour faire une copie
    const deepCopy = (obj: any) => {
      if (typeof obj !== 'object' || obj === null) {
        return obj;
      }

      let copy: any = Array.isArray(obj) ? [] : {};

      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          copy[key] = deepCopy(obj[key]);
        }
      }

      return copy;
    };

    if (destination === 0) {
      if (first === true && destination !== olddestination) {
        hotels = await fetchAllHotels();
        //hotels = hotels.filter((hotel: { id: number; }) => hotel.id === 2 || hotel.id === 3);
        const AllMyPrice = await datesPriceDataProvider.getMyPrice("datePrice", start.toString(), end.toString());

        AllPrices = AllMyPrice
      } else {
        hotels = deepCopy(allhotels)
      }
    } else
      if (destination !== olddestination) {
        if (allhotels.length > 0) {
          hotels = await deepCopy(allhotels.filter((hotel: { id: number; }) => hotel.id === destination));



        } else {
          hotels = await fetchAllHotels();
          const AllMyPrice = await datesPriceDataProvider.getMyPrice("datePrice", start.toString(), end.toString());

          AllPrices = AllMyPrice
        }
      }
      else {
        hotels = await deepCopy(allhotels.filter((hotel: { id: number; }) => hotel.id === destination));


      }

    setmyolddestination(destination)

    await processMyData(hotels, first, type);

    // Lors du premier chargement refaire un filtrer sur tous les resutats
    if (first) {
      const copiedHotels = await deepCopy(hotels);
      setAllHotels(copiedHotels);
      allmyhotel = copiedHotels;

      for (const hotel of hotels) {

      }

      // si une destination filtrer par la destination et par type si il est la 
      if (destination !== 0) {
        hotels = await deepCopy(copiedHotels.filter((hotel: { id: number; }) => hotel.id === destination));
        for (const hotel of hotels) {
          const newData = await getFilteredList(hotel, false);
          if (destination === hotel.id && type !== 0) {

            const resultatFiltre = newData.filter((element: { category_id: number; }) => element.category_id === type);
            hotel.Data = resultatFiltre;
          } else {
            hotel.Data = newData
          }
        }
        // si aucune destination mais que un type filtrer par type
      } else if (type !== 0) {
        hotels = await deepCopy(copiedHotels);
        for (const hotel of hotels) {
          const newData = await getFilteredList(hotel, false);
          const resultatFiltre = newData.filter((element: { category_id: number; }) => element.category_id === type);
          hotel.Data = resultatFiltre;
        }
      } else if (destination === 0 && type === 0 && buttonStates.minnight !== 0) {
        hotels = await deepCopy(copiedHotels);
        for (const hotel of hotels) {
          const newData = await getFilteredList(hotel, false);
          hotel.Data = newData;
        }
      }
    }

    const cureSectionElement = (
      <CureSection
        key="cure-section"
        hotels={hotels}
      />
    );

    setRenduState(cureSectionElement);

    setIsLoading(false);
  }

  const getMyStayPrice = (mews_id: any, hotel: any) => {
    const priceCure = hotel.Products.find((product: { Id: any; }) => product.Id === mews_id);
    if (priceCure) {
      const finalPrice = priceCure.Pricing.Value.GrossValue;
      return finalPrice;

    } else {
      return null
    }
  };

  const getCategoryTitle = (categoryArray: any[]) => {
    const categoryTitle = categoryArray.map((p: { title: any; }) => p.title);
    return categoryTitle;
  };

  async function processMyData(hotels: any, first: boolean, type: number) {
    for (const hotel of hotels) {



      constants = getConstants(hotel.id);
      if (first === false && type > 0) {


        const data = hotel.Data
        const resultatFiltre = data.filter((element: { category_id: number; }) => element.category_id === type);
        hotel.Data = resultatFiltre

      }

      if (!hotel.Data || !hotel.categoryNight || !hotel.cureOption || !hotel.Products || !hotel.Petitdej || !hotel.Avaibility) {
        await fetchData(hotel);
        await fetchAvaibility(hotel);

        for (const stay of Object.values(hotel.Data) as any[]) {

          const selectedObject = await AllPrices.find((obj: { stay: number; }) => obj.stay === stay.id);
          if (selectedObject) {
            stay.MinPriceBedroom = selectedObject.minPrices;
          }
        }

        /*
        for (const item of Object.values(hotel.Data)) {
          await fetchPriceAllBedroom(hotel, item);
        }
        */


        if (first === true) {
          hotel.Data = await getFilteredList(hotel, true);

          // Gérer les promotions
          let startDate = new Date(start);
          startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

          const discounts = await datesPriceDataProvider.getDiscount();

          discounts.resultat.forEach((discount: { hotel_id: string; start_date: string | number | Date; end_date: string | number | Date; dicount_percentage: number }) => {

            let discountStartDate = new Date(discount.start_date);
            discountStartDate = new Date(discountStartDate.getFullYear(), discountStartDate.getMonth(), discountStartDate.getDate());
            let discountEndDate = new Date(discount.end_date);
            discountEndDate = new Date(discountEndDate.getFullYear(), discountEndDate.getMonth(), discountEndDate.getDate());

            const indiceDebut = Math.round((Number(discountStartDate) - Number(startDate)) / (1000 * 3600 * 24));
            const indiceFin = Math.round((Number(discountEndDate) - Number(startDate)) / (1000 * 3600 * 24));


            for (const key in hotel.Data) {
              const myhotel = hotel.Data[key];

              if (discount.hotel_id === myhotel.hotel_id) {

                if (myhotel.MinPriceBedroom) {
                  const nouveauxPrix = [...myhotel.MinPriceBedroom];

                  for (let i = Math.max(indiceDebut, 0); i <= Math.min(indiceFin, nouveauxPrix.length - 1); i++) {
                    nouveauxPrix[i] = nouveauxPrix[i] * (1 - (discount.dicount_percentage / 100));
                  }

                  myhotel.MinPriceBedroom = nouveauxPrix;
                }
              }
            }
          });

        } else {
          hotel.Data = await getFilteredList(hotel, false);
        }
      } else {
        hotel.Data = await getFilteredList(hotel, false);
      }

    }
  }

  function matching(hotel: any) {
    return hotel.Data
      ? hotel.Data.map((stay: { id: any; category_id: any; mews_id: any; }) => {
        const matchedCureOption = hotel.cureOption.filter(
          (cureOptionItem: { id: any; }) => cureOptionItem.id === stay.id
        );
        const matchedcategoryNight = hotel.categoryNight.filter(
          (categoryItem: { id: any; }) => categoryItem.id === stay.category_id
        );

        const category_title = getCategoryTitle(matchedcategoryNight);

        let minPrice = getMyStayPrice(stay.mews_id, hotel);

        if (minPrice) {
          return {
            ...stay,
            ...matchedCureOption,
            category_title,
            minPrice,
          };
        } else {
          return null;
        }
      }).filter((item: null) => item !== null)
      : [];
  }

  const handleNbNightClick = async (minnight: number, maxnight: number, destination: number, first: boolean, Numbertype: number) => {
    if (buttonStates2.minnight === minnight) {
      buttonStates = ({ minnight: 0, maxnight: 0, isActive: false });
      setButtonStates2({ minnight: 0, maxnight: 0, isActive: false });
    } else {
      buttonStates = ({ minnight: minnight, maxnight: maxnight, isActive: true });
      setButtonStates2({ minnight: minnight, maxnight: maxnight, isActive: true });
    }

    if (first === false) {
      await processData(false, olddestination, Number(selectedType))
    } else {
      // Chargement de toutes les destination
      await processData(true, destination, Numbertype);
    }

  };


  function getFilteredList(hotel: any, first: boolean) {
    let filtered = [];
    let matchingDataAndCureOption = matching(hotel)

    if (first) {
      return matchingDataAndCureOption;
    } else

      if (!selectedCategory && !buttonStates.minnight) {
        return matchingDataAndCureOption;
      } else if (selectedCategory && buttonStates.minnight) {
        filtered = matchingDataAndCureOption.filter(
          (item: { category_title: any[]; nights: number; }) =>
            item.nights >= buttonStates.minnight &&
            item.nights <= buttonStates.maxnight
        );
      } else if (selectedCategory) {
        filtered = matchingDataAndCureOption.filter(
          (item: { category_title: any[]; }) => item.category_title[0] === selectedCategory
        );
      } else if (buttonStates.minnight) {
        filtered = matchingDataAndCureOption.filter(
          (item: { nights: number; }) =>
            item.nights >= buttonStates.minnight &&
            item.nights <= buttonStates.maxnight
        );
      }




    return filtered.length !== 0 ? filtered : [];
  }

  const handleDestinationChange = (value: any) => {
    buttonStates = ({ minnight: 0, maxnight: 0, isActive: false });
    setButtonStates2({ minnight: 0, maxnight: 0, isActive: false });
    setIsLoading(true);
    setSelectedDestination(value)
    processData(false, Number(value), Number(selectedType));
  }

  const handleTypeChange = (value: any) => {
    buttonStates = ({ minnight: 0, maxnight: 0, isActive: false });
    setButtonStates2({ minnight: 0, maxnight: 0, isActive: false });
    setIsLoading(true);
    setSelectedType(value)
    processData(false, Number(selectedDestination), Number(value));
  }


  useDocumentTitle('Réservez votre séjour Thalasso sur la Côte Basque');

  return (
    <>
      <Image height={400} fit={"cover"} src={banniere} />
      <Container size="xl" px="xl">
        <Title order={1}>Votre Séjour</Title>
        <Grid>
          <Grid.Col xl={8} md={8} lg={8}>
            <Flex
              direction={{ base: "column", sm: "row" }}
              gap={{ base: "sm", sm: "lg" }}
              justify={{ base: "space-between", sm: "space-between" }}
            >
              <Flex
                direction={{ base: "column", sm: "row" }}
                gap={{ base: "sm", sm: "lg" }}
                justify={{ sm: "center" }}
              >
                <Button
                  uppercase
                  variant="outline"
                  className={
                    buttonStates2.minnight === 1 && buttonStates2.isActive
                      ? "night-nav-active"
                      : "night-nav"
                  }
                  onClick={() => handleNbNightClick(1, 2, 0, false, 0)}
                >
                  1 à 2 nuits
                </Button>
                <Button
                  uppercase
                  variant="outline"
                  className={
                    buttonStates2.minnight === 3 && buttonStates2.isActive
                      ? "night-nav-active"
                      : "night-nav"
                  }
                  onClick={() => handleNbNightClick(3, 5, 0, false, 0)}
                >
                  3 à 5 nuits
                </Button>
                <Button
                  uppercase
                  variant="outline"
                  className={
                    buttonStates2.minnight === 6 && buttonStates2.isActive
                      ? "night-nav-active"
                      : "night-nav"
                  }
                  onClick={() => handleNbNightClick(6, 999, 0, false, 0)}
                >
                  6 nuits et +
                </Button>
              </Flex>
              <Flex
                direction={{ base: "column", sm: "row" }}
                gap={{ base: "xs" }}
                justify={{ sm: "center" }}
                align="center"
              >
                <Text className={"main-text filter"}>Destination</Text>
                <Select
                  sx={{
                    backgroundColor: "white",
                    borderColor: "var(--secondary-color)",
                    color: "var(--secondary-color)",
                  }}
                  placeholder="Toutes"
                  value={selectedDestination}
                  data={alldestination}
                  onChange={handleDestinationChange}
                  styles={() => ({
                    item: {
                      "&[data-selected]": {
                        "&, &:hover": {
                          backgroundColor: "var(--main-color)",
                          color: "white",
                        },
                      },
                      "&[data-hovered]": {},
                    },
                  })}
                />
              </Flex>
              <Flex
                direction={{ base: "column", sm: "row" }}
                gap={{ base: "xs" }}
                justify={{ sm: "center" }}
                align="center"
              >
                <Text className={"main-text filter"}>Type</Text>
                <Select
                  sx={{
                    backgroundColor: "white",
                    borderColor: "var(--secondary-color)",
                    color: "var(--secondary-color)",
                  }}
                  placeholder="Toutes"
                  value={selectedType}
                  data={alltypes}
                  onChange={handleTypeChange}
                  styles={() => ({
                    item: {
                      "&[data-selected]": {
                        "&, &:hover": {
                          backgroundColor: "var(--main-color)",
                          color: "white",
                        },
                      },
                      "&[data-hovered]": {},
                    },
                  })}
                />
              </Flex>

            </Flex>
          </Grid.Col>
          <Grid.Col md={12} lg={4} >

          </Grid.Col>
        </Grid>
        <Space h="xl" />

        {isLoading ? (
          <Text size="lg" align="center">
            <Loader />
          </Text>
        ) : (
          renduState
        )}


      </Container>
    </>
  );
};

export default Home;
