const token = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_API_URL;

const getAllCure = async () => {
  const response = await fetch(`${API_URL}/stay`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  return response.json();
};

const getCureHotel = async (hotel: number) => {
  const response = await fetch(`${API_URL}/stay/hotel/${hotel}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  const data = await response.json();

  // Filtrer les cures actives
  const activeCures = data.filter((cure: { is_active: any; }) => cure.is_active);

  return activeCures;
};

const getAllCureOption = async () => {
  const response = await fetch(`${API_URL}/stay_option`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  return response.json();
};

const cureDataProvider = {
  getCureList: async (cures: string) => {
    if (cures === "cures") {
      const cureslist = await getAllCure();
      return cureslist;
    }

    throw new Error(`Unsupported resource: ${cures}`);
  },
  getCureListHotel: async (cures: string, hotel: number) => {
    if (cures === "cures") {
      const cureslist = await getCureHotel(hotel);
      return cureslist;
    }

    throw new Error(`Unsupported resource: ${cures}`);
  },
  getCureOption: async (option: string) => {
    if (option === "option") {
      return getAllCureOption();
    }

    throw new Error(`Unsupported resource: ${option}`);
  },
};

export default cureDataProvider;
